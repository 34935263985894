.localeChooser {
    font-size:12px;
    display: inline;
    position: absolute;
    right: 0;
    text-align: right;
}

.localeChooser button {
    margin: 0 2px;
}
